import React, { MutableRefObject, useRef } from "react";
import Image from "next/image";
import { Lang } from "../types";
import useVideoButton from "../hooks/useVideoButton";

function Hero({
  lang,
  isMobile,
  reducedMotion,
  isIE,
}: {
  lang: Lang;
  isMobile: boolean;
  reducedMotion: boolean;
  isIE: boolean;
}): JSX.Element {
  const videoElement = useRef() as MutableRefObject<HTMLVideoElement>;
  const { play, visibleButton, togglePlay } = useVideoButton(
    videoElement,
    isIE
  );
  const video = isMobile ? "/hero_mobile.mp4" : "/hero.mp4";

  // reducedMotion is set to true in _app.tsx if isIE, but always becomes true is isIE check below is not made.
  return (
    <section className="hero">
      {!isIE ? (
        <video
          src={video}
          // controls
          // autoPlay
          muted
          loop
          playsInline
          preload="auto"
          ref={videoElement}
          poster="/hero.png"
        >
          <Image
            src="/hero.png"
            alt={lang.hero.alt}
            width="1867"
            height="1057"
            unoptimized
          />
        </video>
      ) : (
        <video src={video} controls muted loop playsInline poster="/hero.png">
          <Image
            src="/hero.png"
            alt={lang.hero.alt}
            width="1867"
            height="1057"
            unoptimized
          />
        </video>
      )}

      <h1 className="hero-text">{lang.hero.title}</h1>
      {!reducedMotion && visibleButton && (
        <div className="video-button-container">
          <button
            className="play-pause"
            type="button"
            onClick={togglePlay}
            aria-label={lang.hero.aria.playpause}
          >
            {play ? lang.hero.videoButton.pause : lang.hero.videoButton.play}
          </button>
        </div>
      )}
    </section>
  );
}

export default Hero;
