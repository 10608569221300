/* eslint-disable import/no-webpack-loader-syntax */

import React, { useRef, useEffect } from "react";
import Image from "next/image";
// import mapboxgl from "mapbox-gl";
// import "mapbox-gl/dist/mapbox-gl.css";
import { Lang } from "../types";

function Mapbox({
  lang,
  isIE,
  loadMapBox,
}: {
  lang: Lang;
  isIE: boolean;
  loadMapBox: boolean;
}): JSX.Element {
  const markerLang = lang.mapbox.marker;
  const notSelectedLanguage = lang.header.buttons.lang;
  const mapImage =
    notSelectedLanguage === "English"
      ? "/mapbox-IE-sv.png"
      : "/mapbox-IE-en.png";

  const mapContainer = useRef<any>(null);

  const geojson = {
    features: [
      {
        type: "Feature",
        properties: {
          title: "Spårvägsmuseet",
          description: "",
        },
        geometry: {
          coordinates: [18.0913566, 59.3579225],
          type: "Point",
        },
      },
    ],
    type: "FeatureCollection",
  };

  useEffect(() => {
    if (loadMapBox) {
      /* @ts-ignore */
      mapboxgl.accessToken =
        "pk.eyJ1IjoibWF0aWxkYWd5c2luZyIsImEiOiJja3NnNTZ1ZG8xaDlvMm9vZDJyM3M2Z2JyIn0.3VFRCKtmDd9pT9b85z7W9w";
      /* @ts-ignore */
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/matildagysing/cksh8g2wp5zhu17uqlcc9e2hx",
        center: [18.0913566, 59.3579225],
        zoom: 12,
        minZoom: 9,
        scrollZoom: false,
        boxZoom: false,
        attributionControl: false,
      });
      /* @ts-ignore */
      map.addControl(new mapboxgl.NavigationControl());
      map.addControl(
        /* @ts-ignore */
        new mapboxgl.AttributionControl({
          compact: false,
        })
      );

      // eslint-disable-next-line no-restricted-syntax
      for (const { geometry } of geojson.features) {
        // create a HTML element for each feature
        const el = document.createElement("div");
        el.className = "marker";
        el.insertAdjacentHTML("afterbegin", markerLang);
        /* @ts-ignore */
        new mapboxgl.Marker(el).setLngLat(geometry.coordinates).addTo(map);
      }

      // Resizing map to full height
      if (map) {
        map.resize();
      }
    }
  }, [lang, loadMapBox, markerLang, geojson.features]);

  return (
    <>
      <h3 style={{ display: "none" }}>{lang.visit.media.title}</h3>
      <figure className="mapbox-container" aria-label={lang.visit.media.title}>
        {!isIE ? (
          <div
            ref={mapContainer}
            className="map-container"
            aria-hidden="true"
            tabIndex={-1}
          />
        ) : (
          <Image
            src={mapImage}
            alt={lang.visit.alt}
            width={867}
            height={877}
            unoptimized
          />
        )}
        <figcaption className="small-text">{lang.visit.address}</figcaption>
      </figure>
    </>
  );
}

export default Mapbox;
