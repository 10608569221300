/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */

import React, { useCallback, useState, useEffect } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import { Lang } from "../types";
import { ArrowLeft, ArrowRight } from "./icons";

function Gallery({
  children,
  lang,
  reducedMotion,
}: {
  children: JSX.Element;
  lang: Lang;
  reducedMotion: boolean;
}): JSX.Element {
  const scrollSpeed = reducedMotion ? 100 : 10;

  const [emblaRef, emblaApi] = useEmblaCarousel({
    skipSnaps: false,
    loop: true,
    speed: scrollSpeed,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<Array<number>>([]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const scrollTo = useCallback(
    (index) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("select", onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  return (
    <>
      <h3 style={{ display: "none" }}>{lang.welcome.media.title}</h3>
      <div className="embla">
        <div className="embla-viewport" ref={emblaRef}>
          <div className="embla-container">{children}</div>
        </div>
        <div className="embla-button-container">
          <button
            type="button"
            className="embla-prev"
            onClick={scrollPrev}
            aria-label={lang.gallery.alt.arrowLeft}
          >
            <ArrowLeft />
          </button>
          <button
            type="button"
            className="embla-next"
            onClick={scrollNext}
            aria-label={lang.gallery.alt.arrowRight}
          >
            <ArrowRight />
          </button>
        </div>
      </div>
      <div className="embla-dots">
        {scrollSnaps.map((_, index) => (
          <div
            key={index}
            aria-label="Click to show image"
            aria-hidden="true"
            role="button"
            onClick={() => scrollTo(index)}
            className={`embla-dot${index === selectedIndex ? "-active" : ""}`}
          />
        ))}
      </div>
    </>
  );
}
export default Gallery;
