import React from "react";

interface SVGPathProps {
  id: number;
  selectedLine: string;
  isMobile: boolean;
  isWide: boolean;
}

export function SVGPath({
  id,
  selectedLine,
  isMobile,
  isWide,
}: SVGPathProps): JSX.Element {
  if (selectedLine === "first") {
    if (!isMobile && !isWide) {
      return (
        <path
          id={`Path_${id}`}
          d="M 1 65 L 1020 65 V 900 L 60 900 V 955 H 1840 V 1020"
          fill="none"
          vectorEffect="non-scaling-stroke"
        />
      );
    }
    if (!isMobile && isWide) {
      return (
        <path
          id={`Path_${id}`}
          d="M 1 60 L 1015 60 V 900 L 60 900 V 952 H 1840 V 2181"
          fill="none"
          vectorEffect="non-scaling-stroke"
        />
      );
    }
    return (
      <path
        id={`Path_${id}`}
        d="M 0 2 H 250 V 50 H 30 V 100 H 330 L 330 1472"
        fill="none"
        vectorEffect="non-scaling-stroke"
      />
    );
  }

  if (selectedLine === "second") {
    if (!isMobile && !isWide) {
      return (
        <path
          id={`Path_${id}`}
          d="M 1840 0 L 1840 60 L 900 60 V 950 H 60 V 1733"
          fill="none"
          vectorEffect="non-scaling-stroke"
        />
      );
    }
    if (!isMobile && isWide) {
      return (
        <path
          id={`Path_${id}`}
          d="M 1840 0 L 1840 60 L 900 60 V 955 H 60 V 2369"
          fill="none"
          vectorEffect="non-scaling-stroke"
        />
      );
    }
    return (
      <path
        id={`Path_${id}`}
        d="M 350 0 H 70 V 50 H 300 V 100 H 20 L 20 1472"
        fill="none"
        vectorEffect="non-scaling-stroke"
      />
    );
  }

  if (selectedLine === "third") {
    if (!isMobile && !isWide) {
      return (
        <path
          id={`Path_${id}`}
          d="M 60 0 L 60 33 H 1015 V 550 H 4603"
          fill="none"
          vectorEffect="non-scaling-stroke"
        />
      );
    }
    if (!isMobile && isWide) {
      return (
        <path
          id={`Path_${id}`}
          d="M 60 0 L 60 38 H 1018 V 550 H 7811"
          fill="none"
          vectorEffect="non-scaling-stroke"
        />
      );
    }
    return (
      <path
        id={`Path_${id}`}
        d="M 0 2 H 250 V 50 H 30 V 100 H 330 L 330 1700"
        fill="none"
        vectorEffect="non-scaling-stroke"
      />
    );
  }

  return <></>;
}

interface SectionProps {
  sectionColor: string;
  id: number;
  distanceToTop: number;
  elementHeight: number;
  selectedLine: string;
  isMobile: boolean;
  isWide: boolean;
  reducedMotion: boolean | undefined;
}

function ScrollLine({
  sectionColor,
  id,
  distanceToTop,
  elementHeight,
  selectedLine,
  isMobile,
  isWide,
  reducedMotion,
}: SectionProps): JSX.Element {
  const lineColor = sectionColor === "red" ? "#ffcd05" : "#b61615";

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", () => {
      const path: SVGGeometryElement | null = document.querySelector(
        `#Path_${id}`
      );
      const fill = document.querySelector(`#fill${id}`);

      if (path && fill) {
        const overlayPathLength = path.getTotalLength();
        fill.setAttributeNS(null, "stroke-dasharray", `${overlayPathLength}`);
        fill.setAttributeNS(null, "stroke", `${lineColor}`);

        let offset = overlayPathLength;

        if (isMobile && !reducedMotion) {
          offset =
            overlayPathLength -
            ((window.pageYOffset - distanceToTop) * overlayPathLength) /
              elementHeight;
        } else if (
          (isMobile && reducedMotion) ||
          (!isMobile && reducedMotion)
        ) {
          offset = overlayPathLength - overlayPathLength;
        } else {
          offset =
            overlayPathLength -
            ((window.pageYOffset - (distanceToTop - 300)) * overlayPathLength) /
              elementHeight;
        }

        fill?.setAttributeNS(null, "stroke-dashoffset", `${offset}`);
      }
    });
  }

  return (
    <div className="svg-wrapper">
      {isMobile ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="-2 -5 354 269"
          id="svg"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <defs>
            <SVGPath
              id={id}
              selectedLine={selectedLine}
              isMobile={isMobile}
              isWide={isWide}
            />
          </defs>
          <use href={`#Path_${id}`} stroke="none" />
          <use
            href={`#Path_${id}`}
            id={`fill${id}`}
            stroke="none"
            strokeWidth="11"
            strokeLinecap="square"
            width="100%"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="-2 -5 1900 1020"
          id="svg"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <defs>
            <SVGPath
              id={id}
              selectedLine={selectedLine}
              isMobile={isMobile}
              isWide={isWide}
            />
          </defs>
          <use href={`#Path_${id}`} />
          <use
            href={`#Path_${id}`}
            id={`fill${id}`}
            // stroke={lineColor}
            stroke="none"
            strokeWidth="11"
            strokeLinecap="square"
            width="100%"
          />
        </svg>
      )}
    </div>
  );
}

export default ScrollLine;
