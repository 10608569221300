import React, { ReactNode, useRef } from "react";
import ScrollLine from "./scrollLine";

interface SectionProps {
  sectionName: string | undefined;
  title: string;
  media: any;
  description: string;
  infoBoxes: ReactNode | undefined;
  sectionColor: string;
  id: number;
  isMobile: boolean;
  isWide: boolean;
  selectedLine: string;
  reducedMotion: boolean | undefined;
  elementHeight: number;
  distanceToTop: number;
}

function Section({
  title,
  sectionName,
  media,
  description,
  infoBoxes,
  sectionColor,
  id,
  isMobile,
  isWide,
  selectedLine,
  reducedMotion,
  elementHeight,
  distanceToTop,
}: SectionProps): JSX.Element {
  const sectionColorClass = sectionColor === "red" ? "red" : "yellow";

  const elementOffsetRef = useRef<HTMLDivElement>(null);

  if (!isMobile && sectionColor === "red") {
    return (
      <section ref={elementOffsetRef}>
        <div className={sectionColorClass}>
          <div className="container">
            <div className="text-media-container">
              <section className="media">{media}</section>
              <div className="text-container textRight">
                <div className="section-name">{sectionName}</div>
                <h2>{title}</h2>
                <p>{description}</p>
              </div>
            </div>
            {infoBoxes && infoBoxes}
          </div>
          <ScrollLine
            id={id}
            sectionColor={sectionColor}
            elementHeight={elementHeight}
            distanceToTop={distanceToTop}
            selectedLine={selectedLine}
            isMobile={isMobile}
            isWide={isWide}
            reducedMotion={reducedMotion}
          />
        </div>
      </section>
    );
  }
  if (!isMobile && sectionColor === "yellow") {
    return (
      <section ref={elementOffsetRef}>
        <div className={sectionColorClass}>
          <div className="container">
            <div className="text-media-container">
              <div className="text-container textLeft">
                <div className="section-name">{sectionName}</div>
                <h2>{title}</h2>
                <p>{description}</p>
              </div>
              <section className="media">{media}</section>
            </div>
            {infoBoxes && infoBoxes}
          </div>
          <ScrollLine
            id={id}
            sectionColor={sectionColor}
            elementHeight={elementHeight}
            distanceToTop={distanceToTop}
            selectedLine={selectedLine}
            isMobile={isMobile}
            isWide={isWide}
            reducedMotion={reducedMotion}
          />
        </div>
      </section>
    );
  }
  return (
    <section id={id.toString()}>
      <div className={sectionColorClass} ref={elementOffsetRef}>
        <div className="container">
          <div>
            <div className="section-name">{sectionName}</div>
            <h2>{title}</h2>
          </div>
          <div>{media}</div>
          <div>
            <p>{description}</p>
          </div>
          {infoBoxes && infoBoxes}
        </div>
        <ScrollLine
          id={id}
          sectionColor={sectionColor}
          elementHeight={elementHeight}
          distanceToTop={distanceToTop}
          selectedLine={selectedLine}
          isMobile={isMobile}
          isWide={isWide}
          reducedMotion={reducedMotion}
        />
      </div>
    </section>
  );
}
export default Section;
