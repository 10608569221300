/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from "react";
import lottie from "lottie-web/build/player/lottie_light";
import mobileLogo from "../public/SPVM_341x470_logo.json";
import desktopLogo from "../public/SPVM_1440x1024_logo.json";

// interface AnimationConfig {
//   container: HTMLElement;
//   animationData: File;
//   renderer: string;
//   loop: boolean | number;
//   autoplay: boolean;
// }

function StartScreen(): JSX.Element {
  const [show, setShow] = useState(true);
  const [showAnim, setShowAnim] = useState(true);

  useEffect(() => {
    lottie.loadAnimation({
      // @ts-ignore
      container: document.querySelector("#desktop-logo"),
      animationData: desktopLogo,
      renderer: "svg",
      loop: false,
      autoplay: true,
    });
    lottie.loadAnimation({
      // @ts-ignore
      container: document.querySelector("#mobile-logo"),
      animationData: mobileLogo,
      renderer: "svg",
      loop: false,
      autoplay: true,
    });

    const animationTime = setTimeout(() => {
      setShowAnim(false);
    }, 5000);

    return () => {
      clearTimeout(animationTime);
    };
  }, []);

  useEffect(() => {
    let showTime: NodeJS.Timeout;

    if (showAnim === false) {
      showTime = setTimeout(() => {
        setShow(false);
      }, 200);
    }

    return () => {
      clearTimeout(showTime);
    };
  }, [showAnim]);

  return show ? (
    <section
      className="start-screen"
      style={showAnim ? { opacity: "1" } : { opacity: "0" }}
      onClick={() => {
        setShowAnim(false);
      }}
    >
      <div className="animation-desktop" id="desktop-logo" />
      <div className="animation-mobile" id="mobile-logo" />
    </section>
  ) : (
    <></>
  );
}

export default StartScreen;
