import React, { useState } from "react";
import Image from "next/image";
import { Lang } from "../types";

function InfoBox({
  children,
  title,
  isMobile,
  lang,
}: {
  children: JSX.Element;
  title: string;
  isMobile: boolean;
  lang: Lang;
}): JSX.Element {
  const [openBox, setOpenBox] = useState(false);

  return (
    <div className="info-box">
      {isMobile ? (
        <>
          <div
            className="title-container"
            role="button"
            onClick={() => setOpenBox(!openBox)}
            onKeyDown={() => setOpenBox(!openBox)}
            tabIndex={0}
          >
            <h3>{title}</h3>
            {!openBox ? (
              <Image
                src="/caretDown.svg"
                alt={lang.infoBoxes.alt.caretDown}
                width="30"
                height="15"
                unoptimized
              />
            ) : (
              <Image
                src="/caretUp.svg"
                alt={lang.infoBoxes.alt.caretUp}
                width="30"
                height="15"
                unoptimized
              />
            )}
          </div>
          <div
            className="content-container"
            style={
              !openBox
                ? { maxHeight: "0", marginTop: "0", opacity: "0" }
                : { maxHeight: "10000px", marginTop: "1rem", opacity: "1" }
            }
          >
            {children}
          </div>
        </>
      ) : (
        <>
          <h3>{title}</h3>
          <div className="content-container">{children}</div>
        </>
      )}
    </div>
  );
}

function InfoBoxes({
  isMobile,
  lang,
}: {
  isMobile: boolean;
  lang: Lang;
}): JSX.Element {
  return (
    <section className="info-container">
      <InfoBox
        title={lang.infoBoxes.discover.title}
        isMobile={isMobile}
        lang={lang}
      >
        <div>
          <p>{lang.infoBoxes.discover.text}</p>
          <ul>
            {lang.infoBoxes.discover.list.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      </InfoBox>
      <InfoBox
        title={lang.infoBoxes.schoolInformation.title}
        isMobile={isMobile}
        lang={lang}
      >
        <>
          <p>
            {lang.infoBoxes.schoolInformation.text[0]}
            <a href={`mailto:${lang.infoBoxes.schoolInformation.email}`}>
              {lang.infoBoxes.schoolInformation.email}
            </a>
            {lang.infoBoxes.schoolInformation.text[1]}
          </p>
          <a
            href="https://stockholmskallan.stockholm.se/skola/"
            rel="noreferrer"
            className="info-link"
          >
            {lang.infoBoxes.schoolInformation.button}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.4002 0.599976H13.7162V2.48398H20.2322L13.0742 9.64197L14.3582 10.926L21.5162 3.76798V10.284H23.4002V0.599976Z"
                fill="#FFCD05"
                stroke="#FFCD05"
                strokeWidth="0.5"
                strokeMiterlimit="10"
              />
              <path
                d="M23.3996 23.4H0.599609V0.599976H9.59961V2.39998H2.39961V21.6H21.5996V14.4H23.3996V23.4Z"
                fill="#FFCD05"
                stroke="#FFCD05"
                strokeWidth="0.5"
                strokeMiterlimit="10"
              />
            </svg>
          </a>
        </>
      </InfoBox>
      <InfoBox
        title={lang.infoBoxes.ourCollections.title}
        isMobile={isMobile}
        lang={lang}
      >
        <>
          <p>
            {lang.infoBoxes.ourCollections.text[0]}
            <span className="no-break">
              {lang.infoBoxes.ourCollections.text[1]}
            </span>
            {lang.infoBoxes.ourCollections.text[2]}
            <span className="no-break">
              {lang.infoBoxes.ourCollections.text[3]}
            </span>
            {lang.infoBoxes.ourCollections.text[4]}
            <span className="no-break">
              {lang.infoBoxes.ourCollections.text[5]}
            </span>
            {lang.infoBoxes.ourCollections.text[6]}
            <span className="no-break">
              {lang.infoBoxes.ourCollections.text[7]}
            </span>
            {lang.infoBoxes.ourCollections.text[8]}
          </p>
        </>
      </InfoBox>
    </section>
  );
}
export default InfoBoxes;
