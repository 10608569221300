/* eslint-disable spaced-comment */

import React, {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Head from "next/head";
import Image from "next/image";
import Script from "next/script";
import Hero from "../components/hero";
import Section from "../components/homepageSection";
import StartScreen from "../components/startScreen";
import Gallery from "../components/gallery";
import Mapbox from "../components/mapbox";
import useWindowSize from "../hooks/useWindowSize";
import InfoBoxes from "../components/infoBoxes";

const HomePage = forwardRef((props, ref): JSX.Element => {
  const { width } = useWindowSize();
  const isMobileMedium = width < 992;
  const isMobileSmall = width < 767;
  const isWide = width > 1500;

  const firstRef = useRef() as MutableRefObject<HTMLDivElement>;
  const secondRef = useRef() as MutableRefObject<HTMLDivElement>;
  const thirdRef = useRef() as MutableRefObject<HTMLDivElement>;

  // eslint-disable-next-line
  // @ts-ignore
  const { lang, reducedMotion, handleRefNavs, isIE } = props.children;

  const [elementOffsetHeight, setElementOffsetHeight] = useState({
    firstRef: 0,
    secondRef: 0,
    thirdRef: 0,
  });
  const [elementOffsetTop, setElementOffsetTop] = useState({
    firstRef: 0,
    secondRef: 0,
    thirdRef: 0,
  });

  useEffect(() => handleRefNavs());

  useImperativeHandle(ref, () => ({
    secondRef,
    thirdRef,
  }));

  const [loadMapBox, setLoadMapBox] = useState(false);

  useEffect(() => {
    if (firstRef.current && secondRef.current && thirdRef.current) {
      setElementOffsetHeight({
        firstRef: firstRef.current.offsetHeight,
        secondRef: secondRef.current.offsetHeight,
        thirdRef: thirdRef.current.offsetHeight,
      });

      setElementOffsetTop({
        firstRef: firstRef.current.offsetTop,
        secondRef: secondRef.current.offsetTop,
        thirdRef: thirdRef.current.offsetTop,
      });
    }
  }, [width]);

  // console.log("isIE", isIE);
  // console.log("reducedMotion", reducedMotion);

  return (
    <>
      {!isIE && (
        <Script
          src="https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.js"
          strategy="afterInteractive"
          // strategy="lazyOnload"
          // strategy="beforeInteractive"
          onLoad={() => {
            setLoadMapBox(true);
          }}
        />
      )}

      <Head>
        <title>Spårvägsmuseet</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="author" content="Familjen" />
        <meta
          name="description"
          content="På Spårvägsmuseet lär du dig om Stockholms historia genom kollektivtrafiken. Museet är för närvarande stängt men öppnar igen i början av 2022."
        />
        <link href="/favicon.png" rel="shortcut icon" />
        {/* Facebook */}
        <meta content="/hero.png" property="og:image" />
        <meta
          content="På Spårvägsmuseet lär du dig om Stockholms historia genom kollektivtrafiken. Museet är för närvarande stängt men öppnar igen i början av 2022."
          property="og:description"
        />
        <meta content="Spårvägsmuseet" property="og:title" />
        <meta content="https://sparvagsmuseet.se/" property="og:url" />
        {/* Twitter */}
        <meta content="summary" name="twitter:card" />
        <meta content="Spårvägsmuseet" name="twitter:title" />
        <meta
          content="På Spårvägsmuseet lär du dig om Stockholms historia genom kollektivtrafiken. Museet är för närvarande stängt men öppnar igen i början av 2022."
          name="twitter:description"
        />
        <meta content="/hero.png" name="twitter:image" />
        <script
          dangerouslySetInnerHTML={{
            __html: `history.scrollRestoration = "manual"`,
          }}
        />
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.css"
          rel="stylesheet"
        />
      </Head>

      {reducedMotion === false && isIE === false && <StartScreen />}
      <Hero
        lang={lang}
        isMobile={isMobileSmall}
        reducedMotion={reducedMotion}
        isIE={isIE}
      />
      <div ref={firstRef}>
        <Section
          sectionName=""
          title={lang.welcome.title}
          media={
            <Gallery lang={lang} reducedMotion={reducedMotion}>
              <>
                <div className="embla-slide">
                  <div className="embla-slide-inner">
                    <Image
                      src="/IMG_9205.jpg"
                      alt={lang.gallery.alt.first[0]}
                      width="1920"
                      height="1500"
                      unoptimized
                      key={1}
                    />
                  </div>
                </div>
                <div className="embla-slide">
                  <div className="embla-slide-inner">
                    <Image
                      src="/IMG_9135.jpg"
                      alt={lang.gallery.alt.first[1]}
                      width="1920"
                      height="1500"
                      unoptimized
                      key={2}
                    />
                  </div>
                </div>
                <div className="embla-slide">
                  <div className="embla-slide-inner">
                    <Image
                      src="/IMG_8954.jpg"
                      alt={lang.gallery.alt.first[2]}
                      width="1920"
                      height="1500"
                      unoptimized
                      key={3}
                    />
                  </div>
                </div>
                <div className="embla-slide">
                  <div className="embla-slide-inner">
                    <Image
                      src="/IMG_9001.jpg"
                      alt={lang.gallery.alt.first[3]}
                      width="1920"
                      height="1500"
                      unoptimized
                      key={3}
                    />
                  </div>
                </div>
              </>
            </Gallery>
          }
          description={lang.welcome.text}
          sectionColor="red"
          id={1}
          isMobile={isMobileMedium}
          isWide={isWide}
          selectedLine="first"
          infoBoxes={undefined}
          reducedMotion={reducedMotion}
          elementHeight={elementOffsetHeight.firstRef}
          distanceToTop={elementOffsetTop.firstRef}
        />
      </div>
      <div ref={secondRef}>
        <Section
          sectionName={lang.visit.sectionName}
          title={lang.visit.title}
          media={<Mapbox lang={lang} isIE={isIE} loadMapBox={loadMapBox} />}
          description={lang.visit.text}
          sectionColor="yellow"
          id={2}
          isMobile={isMobileMedium}
          isWide={isWide}
          selectedLine="second"
          infoBoxes={undefined}
          reducedMotion={reducedMotion}
          elementHeight={elementOffsetHeight.secondRef}
          distanceToTop={elementOffsetTop.secondRef}
        />
      </div>
      <div ref={thirdRef}>
        <Section
          sectionName={lang.about.sectionName}
          title={lang.about.title}
          media={
            <Gallery lang={lang} reducedMotion={reducedMotion}>
              <>
                <div className="embla-slide">
                  <div className="embla-slide-inner">
                    <Image
                      src="/2108-1001-1920x1500.jpg"
                      alt={lang.gallery.alt.second[0]}
                      width="1920"
                      height="1500"
                      unoptimized
                      key={1}
                    />
                  </div>
                </div>
                <div className="embla-slide">
                  <div className="embla-slide-inner">
                    <Image
                      src="/IMG_8940.jpg"
                      alt={lang.gallery.alt.second[1]}
                      width="1920"
                      height="1500"
                      unoptimized
                      key={2}
                    />
                  </div>
                </div>
                <div className="embla-slide">
                  <div className="embla-slide-inner">
                    <Image
                      src="/IMG_9219.jpg"
                      alt={lang.gallery.alt.second[2]}
                      width="1920"
                      height="1500"
                      unoptimized
                      key={3}
                    />
                  </div>
                </div>
                <div className="embla-slide">
                  <div className="embla-slide-inner">
                    <Image
                      src="/2108-561-1920x1500.jpg"
                      alt={lang.gallery.alt.second[3]}
                      width="1920"
                      height="1500"
                      unoptimized
                      key={3}
                    />
                  </div>
                </div>
              </>
            </Gallery>
          }
          description={lang.about.text}
          infoBoxes={<InfoBoxes isMobile={isMobileMedium} lang={lang} />}
          sectionColor="red"
          id={3}
          isMobile={isMobileMedium}
          isWide={isWide}
          selectedLine="third"
          reducedMotion={reducedMotion}
          elementHeight={elementOffsetHeight.thirdRef}
          distanceToTop={elementOffsetTop.thirdRef}
        />
      </div>
    </>
  );
});
export default HomePage;
