import { useState, useEffect, MutableRefObject } from "react";

const useVideoButton = (
  videoElement: MutableRefObject<HTMLVideoElement>,
  isIE: boolean
) => {
  const [play, setPlay] = useState(true);
  const [visibleButton, setVisibleButton] = useState(false);

  const togglePlay = () => {
    setPlay(!play);
  };

  useEffect(() => {
    if (!isIE) {
      const playPromise = videoElement.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            if (play) videoElement.current.play();
            else videoElement.current.pause();
            setVisibleButton(true)
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [play, videoElement, isIE]);

  return {
    play,
    visibleButton,
    setPlay,
    togglePlay,
  };
};

export default useVideoButton;
